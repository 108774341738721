<template>
    <div class="game-thumbnail-component col-4 col-sm-4 col-md-2" :class="{ 'full-width': fullWidth, 'col-6': configStore.config.gameThumbnailGrid === 'normal' }" v-if="gameData">
        <div class="card m-1 game-container text-center" @click="startGame()" v-lazy-container="{ selector: 'img', loading: `${getUrl('ourAssets')}/ourAssets/${configStore.config.skin}/lazyImg.jpg`, error: `${getUrl('ourAssets')}/ourAssets/${configStore.config.skin}/lazyImg.jpg` }">
            <img :data-src="`${getUrl('ourAssets')}/games/${configStore.config.gameThumbnailSize}/${gameData.gameId}.jpg`" alt="Casino Game" class="img-fluid" />

            <!-- Log In or Play Icons -->
            <font-awesome-icon class="game-details icon" icon="fa-solid fa-lock" v-if="gameData.loginRequired && myStore.loggedIn === false" shake />
            <font-awesome-icon class="game-details icon" icon="fa-solid fa-play" v-else shake />

            <!-- Thumbnail Info -->
            <small class="game-details studio" v-if="configStore.config.thumbnailDetails === 'inside'">
                <!-- Inside Thumbnail Info -->
                <strong>{{ getStudio(gameData.studio) }}</strong>
            </small>
            <small class="outside-game-details ps-1" v-else-if="configStore.config.gameThumbnailGrid !== 'small' && configStore.config.thumbnailDetails === 'outside'">
                <!-- Outside Thumbnail Info -->
                {{ gameData.name }}<br />
                <strong>{{ getStudio(gameData.studio) }}</strong>
            </small>

            <!-- Evolution Live Stats -->
            <template v-if="liveStats">
                <span v-if="liveStats.players" class="liveStats players" :class="{ middle: configStore.config.gameThumbnailSize === '512-288' }"><font-awesome-icon icon="fa-regular fa-user" /> {{ liveStats.players }}</span>
                <span v-if="liveStats.betLimits || liveStats.results" class="liveStats betsOrNumbers my-row" :class="{ outside: configStore.config.thumbnailDetails === 'outside' }">
                    <div class="col-5 text-start" :class="{ 'col-12 text-center': configStore.config.gameThumbnailGrid === 'small' }">
                        <template v-if="liveStats.betLimits">
                            <span class="betLimits">{{ getBetLimits(liveStats.betLimits) }}</span>
                        </template>
                    </div>
                    <div class="col-7 text-end" v-if="configStore.config.gameThumbnailGrid !== 'small'">
                        <template v-if="liveStats.results">
                            <span v-for="result in liveStats.results.slice(0, 3)" class="results" :class="rouletteResultsColour(result)">{{ result }}</span>
                        </template>
                    </div>
                </span>
            </template>

            <div class="game-black-overlay"></div>
        </div>
    </div>
</template>

<script>
import { getUrl, findGame, getSymbolFromCurrencyCode } from "../../helpers/helpers";
import { startGame, getLiveGameStats } from "../../services/tma/axiosTmaRequests";

export default {
    name: "game-thumbnail-component",
    data: function () {
        return {
            status: null,
            gameData: null,
            findGameIntervalId: null,
            liveStats: null,
        };
    },
    components: {},
    props: {
        game: Object,
        noDetails: Boolean,
        fullWidth: Boolean,
        game_id: String,
    },
    created() {
        if (this.game) {
            this.gameData = this.game;
        } else if (this.game_id) {
            if (this.perCallsStore.allGamesArray) {
                this.gameData = findGame(this.perCallsStore.allGamesArray, this.game_id);
            } else {
                this.findGameIntervalId = setInterval(
                    function () {
                        if (this.perCallsStore.allGamesArray) {
                            this.gameData = findGame(this.perCallsStore.allGamesArray, this.game_id);
                            clearInterval(this.findGameIntervalId);
                        }
                    }.bind(this),
                    500,
                );
            }
        }
    },
    computed: {
        liveGameStatsArray() {
            return this.callsStore.liveGameStatsArray;
        },
    },
    watch: {
        gameData() {
            this.gameLiveStats();
        },
        liveGameStatsArray() {
            this.gameLiveStats();
        },
    },
    methods: {
        getStudio(studio){
            studio = studio === "WAZDAN" ? "VOLTENT" : studio;
            return studio.split("_").join(" ");
        },
        startGame() {
            if (this.gameData.loginRequired && this.myStore.loggedIn === false) {
                this.$router.push("/login");
            } else if (this.myStore.disabledGameThumbnails === false) {
                this.myStore.setDisabledGameThumbnails(true);
                startGame(this.$router, this.configStore, this.gameData, this.callsStore, this.myStore, this.perCallsStore);
            }
        },
        gameLiveStats() {
            if (this.gameData.studio === "EVOLUTION") {
                this.liveGameStatsArray.some((gameLiveStats) => {
                    if (this.gameData.gameId === gameLiveStats.gameId) {
                        this.liveStats = gameLiveStats;
                        return true;
                    }
                });
            }
        },
        rouletteResultsColour(result) {
            const redNumbers = ["3", "12", "7", "18", "9", "14", "1", "16", "5", "23", "30", "36", "27", "34", "25", "21", "19", "32", "B", "Dn", "R"];
            const blackNumbers = ["26", "35", "28", "29", "22", "31", "20", "33", "24", "10", "8", "11", "13", "6", "17", "2", "4", "15"];
            if (redNumbers.indexOf(result) !== -1) {
                return "red";
            } else if (blackNumbers.indexOf(result) !== -1) {
                return "black";
            } else if (["P", "L"].indexOf(result) !== -1) {
                return "blue";
            } else if (["Tr", "T"].indexOf(result) !== -1) {
                return "yellow";
            }
            return "green";
        },
        getBetLimits(betLimits) {
            let finalLimits;
            let playerCurrencyCode = this.callsStore.realBalanceObject && this.callsStore.realBalanceObject.currencyCode ? this.callsStore.realBalanceObject.currencyCode : "EUR";
            betLimits.map((limits) => {
                if (limits.currencyCode === playerCurrencyCode) {
                    finalLimits = `${getSymbolFromCurrencyCode(playerCurrencyCode)}${limits.min} - ${getSymbolFromCurrencyCode(playerCurrencyCode)}${limits.max}`;
                }
            });
            return finalLimits;
        },
    },
};
</script>

<style lang="scss">
.game-thumbnail-component.full-width {
    width: 100%;
}
.game-thumbnail-component {
    .game-container {
        position: relative;
        border: none;
        background-color: transparent;
        .game-details {
            position: absolute;
            margin: 0 auto;
            left: 0;
            right: 0;
            color: var(--game-hover-text);
            z-index: 2;
            cursor: pointer;
            &.studio {
                bottom: 0px;
                background-color: rgba(0, 0, 0, 0.6);
                padding: 3px;
                font-size: 12px;
                text-shadow: 1px 1px 2px #000, 0 0 1em #000, 0 0 0.2em #000, 0 0 1em #000, 0 0 0.2em #000;
            }
            @media only screen and (min-width: $min-width-md) {
                &.studio {
                    padding: 5px;
                    font-size: 14px;
                }
            }
            &.icon {
                display: none;
                height: 26px;
                top: calc(50% - 13px);
                color: var(--first-color);
            }
        }
        .outside-game-details {
            text-align: left;
            overflow: hidden;
            white-space: nowrap;
            color: var(--game-hover-text);
            background-color: var(--my-block-background);
            border-top: 2px solid var(--first-color);
        }
        .game-black-overlay {
            display: none;
            background-color: var(--game-hover);
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 1;
            cursor: pointer;
        }
        &:hover {
            .game-details,
            .game-black-overlay {
                display: block;
            }
        }

        .liveStats {
            position: absolute;
            background-color: rgba(0, 0, 0, 0.5);
            color: var(--game-hover-text);
            font-size: 12px;
            text-shadow: 1px 1px 2px #000, 0 0 1em #000, 0 0 0.2em #000, 0 0 1em #000, 0 0 0.2em #000;
            padding: 2px 8px;
            overflow: hidden;
            white-space: nowrap;
            &.players {
                right: 0;
                margin: 5px;
                border-radius: var(--site-radius);
                &.middle {
                    left: 50%;
                    transform: translate(-50%);
                }
            }
            &.betsOrNumbers {
                left: 0;
                right: 0;
                bottom: 24px;
                .results {
                    margin: 2px;
                    padding: 0 3px;
                    border-radius: 5px;
                    color: white;
                    &.black {
                        background-color: black;
                    }
                    &.red {
                        background-color: red;
                    }
                    &.green {
                        background-color: green;
                    }
                    &.blue {
                        background-color: blue;
                    }
                    &.yellow {
                        background-color: yellow;
                        color: black;
                    }
                }
            }
            @media only screen and (min-width: $min-width-md) {
                &.betsOrNumbers {
                    bottom: 31px;
                }
            }
            &.outside {
                left: 0;
                right: 0;
                bottom: 44px;
            }
        }
    }
}
</style>
