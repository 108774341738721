<template>
    <!-- <div class="form-group field"> -->
    <div class="field" :class="configStore.config.formStyle === 'old' ? 'old-form-group' : 'modern-form-group', margin === 'yes' ? 'me-1' : 'me-0'">
        
        <input v-if="configStore.config.formStyle === 'old'" :type="type" class="form-field" :class="{ error: v$.$error, success: v$.$dirty && !v$.$error, number: type === 'number', notEmpty: !empty }" :placeholder="getDictionary(fieldName)" v-model.trim="v$.$model" required :autocomplete="autocomplete" :disabled="disabled" />
        <input v-else :type="type" class="form-field" :class="{ error: v$.$error, success: v$.$dirty && !v$.$error, number: type === 'number' }" :placeholder="fieldName" v-model.trim="v$.$model" required :autocomplete="autocomplete" :disabled="disabled" />
        
        <label :for="fieldName" class="form-label" :class="{ notEmpty: !empty }">{{ getDictionary(fieldName) }}</label>
        
        <div v-if="v$.$dirty" class="validation text-start">
            <label class="validation-message" v-if="v$.required && v$.required.$invalid">{{ getDictionary("REQUIRED") }}</label>
            <label class="validation-message" v-else-if="v$.email && v$.email.$invalid">{{ getDictionary("EMAIL_VAL") }}</label>
            <label class="validation-message" v-else-if="v$.minLength && v$.minLength.$invalid">{{ getDictionary("MIN_VAL") }}</label>
            <label class="validation-message" v-else-if="v$.maxLength && v$.maxLength.$invalid">{{ getDictionary("MAX_VAL") }}</label>
            <label class="validation-message" v-else-if="v$.minValue && v$.minValue.$invalid && fieldName !== 'AMOUNT'">{{ getDictionary("INVALID_ENTRY") }}</label>
            <label class="validation-message" v-else-if="v$.maxValue && v$.maxValue.$invalid && fieldName !== 'AMOUNT'">{{ getDictionary("INVALID_ENTRY") }}</label>
            <label class="validation-message" v-else-if="v$.cardExpieryDate && v$.cardExpieryDate.$invalid">{{ getDictionary("INVALID_ENTRY") }}</label>
            <label class="validation-message" v-else-if="v$.passwordStrength && v$.passwordStrength.$invalid">{{ getDictionary("PASS_VAL") }}</label>
            <label class="validation-message" v-else-if="v$.sameAsPassword && v$.sameAsPassword.$invalid">{{ getDictionary("SAME_PASS_VAL") }}</label>
            <label class="validation-message" v-else-if="v$.cleanDataRegex && v$.cleanDataRegex.$invalid">{{ getDictionary("INVALID_CHAR_VAL") }}</label>
            <label class="validation-message" v-else-if="(v$.existingEmail && v$.existingEmail.$invalid) || (v$.existingPhone && v$.existingPhone.$invalid)">{{ getDictionary("USER_ALREADY_EXISTS") }}</label>
            <label class="validation-message" v-else-if="v$.isValidCardNumber && v$.isValidCardNumber.$invalid">{{ getDictionary("INVALID_CARD_NUMBER") }}</label>
            <label class="validation-message" v-else-if="v$.isValidIban && v$.isValidIban.$invalid">{{ getDictionary("INVALID_IBAN_NUMBER") }}</label>
            <label class="validation-message" v-else-if="v$.numeric && v$.numeric.$invalid">{{ getDictionary("NOT_A_NUMBER") }}</label>
        </div>
    </div>
</template>

<script>
export default {
    name: "form-field-component",
    props: {
        type: String,
        v$: Object,
        fieldName: String,
        autocomplete: String,
        margin: String,
        disabled: Boolean
    },
    computed: {
        empty() {
            return this.v$.$model === "" || this.v$.$model === null;
        },
    },
};
</script>
