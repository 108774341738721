import { defineStore } from "pinia";
import { deviceInfo, getCookie } from "../helpers/helpers";
import { getBlackbox } from "../helpers/iovation_helpers";
import { createPayment, updateUser } from "../services/tma/axiosTmaRequests";

export const usePaymentsStore = defineStore("paymentsStore", {
    state: () => ({
        bonusCode: null,
        minimumBonusAmount: null,
        amount: null,
        validAmount: false,
        account: null,
        validAccount: true,
        query: null,
        selectedPayment: null,
        createPaymentStatus: null,
        biggerBonusAmountView: false,
        autoDetectedPlayerBlock: false,
    }),
    actions: {
        setBonusCode(newValue) {
            this.bonusCode = newValue;
        },
        setBiggerBonusAmountView(newValue) {
            this.biggerBonusAmountView = newValue;
        },
        setMinimumBonusAmount(newValue) {
            this.minimumBonusAmount = newValue;
        },
        setAmount(newValue) {
            this.amount = newValue;
        },
        setValidAmount(newValue) {
            this.validAmount = newValue;
        },
        setAccount(newValue) {
            this.account = newValue;
        },
        setValidAccount(newValue) {
            this.validAccount = newValue;
        },
        setQuery(newValue) {
            this.query = newValue;
        },
        setSelectedPayment(newValue, autoSelected) {
            if (newValue && this.selectedPayment && this.selectedPayment.slug === newValue.slug && autoSelected !== true) {
                this.selectedPayment = null;
            } else {
                this.selectedPayment = newValue;
            }
        },
        shouldBeVisible(paymentSlug) {
            if (paymentSlug === "WALLET_APPLEPAY" && (deviceInfo().isMobile === false || deviceInfo().os !== "ios")) {
                return false;
            }
            return true;
        },
        setCreatePaymentStatus(status) {
            this.createPaymentStatus = status;
        },
        setAutoDetectedPlayerBlock(newValue) {
            this.autoDetectedPlayerBlock = newValue;
        },
        createQueryInput(currencyCode, type) {
            if (this.validAmount && this.validAccount) {
                // method: ${this.selectedPayment.method},
                // provider: ${this.selectedPayment.provider},
                // method: ${this.selectedPayment.provider === "MANDATO" ? "WALLET" : this.selectedPayment.method},
                // provider: ${this.selectedPayment.provider === "MANDATO" ? "CITIZEN" : this.selectedPayment.provider},

                let query = `
                amount: "${this.amount}",
                currencyCode: "${currencyCode}",
                gateway: ${this.selectedPayment.gateway},
                method: ${this.selectedPayment.method},
                provider: ${this.selectedPayment.provider},
                type: ${type},
                redirectUri: "${document.location.origin}",
                iovationBlackbox: "${getBlackbox()}"
                `;

                if (this.bonusCode) {
                    query = `${query}, selectedBonusCode: "${this.bonusCode}"`;
                }

                if (this.query) {
                    query = `${query}, ${this.query}`;
                }

                if (this.account) {
                    query = `${query}, ${this.account}`;
                }

                return `{${query}}`;
            } else {
                return null;
            }
        },
        createPayment(myStore, callsStore, router, config, perCallsStore, type) {
            // Block Player Functionality
            // 1. If a user is registered with no affiliate id, he is a first time depositor and opens the credit card screen
            // 2. We set the amount value to 20 and we deselect/uncheck the deposit bonus
            // 3. If he clicks on deposit button without changing the amount value and selecting a bonus we show the KYC
            if (callsStore.userObject.kycProfile.verified === false && this.selectedPayment && this.selectedPayment.slug === "CARD_PAYMENT_IQ" && getCookie("depositedCustomer") === "false" && callsStore.userObject && callsStore.userObject.affiliateId === null && this.bonusCode === null && this.amount === "20") {
                this.setAutoDetectedPlayerBlock(true);
                this.setCreatePaymentStatus("");

                const queryInput = `{userId: "${getCookie("userId")}" nickname: "toCheck${Math. floor(Math. random() * (9999 - 1000 + 1)) + 1000}"}`;
                updateUser(queryInput, myStore, getCookie("accessToken"), callsStore, router, config.skin, perCallsStore);
            } else {
                if (this.validAmount && this.biggerBonusAmountView === false && type === "DEPOSIT" && this.minimumBonusAmount && parseFloat(this.minimumBonusAmount) > this.amount) {
                    this.setBiggerBonusAmountView(true);
                    this.setCreatePaymentStatus("");
                } else {
                    this.setBiggerBonusAmountView(false);
                    const queryInput = this.createQueryInput(callsStore.realBalanceObject.currencyCode, type);
                    if (queryInput !== null) {
                        perCallsStore.setLastDepositMethodSlug(this.selectedPayment.slug);

                        createPayment(queryInput, myStore, getCookie("accessToken"), callsStore, router, config.skin, perCallsStore, type).then((response) => {
                            if (response.success && response.data.redirect !== null) {
                                // Deposit
                                if (response.data.redirect.uri) {
                                    window.top.location.href = response.data.redirect.uri;
                                } else if (response.data.redirect.html) {
                                    const parser = new DOMParser();
                                    const html = parser.parseFromString(response.data.redirect.html, "text/html");
                                    let form = html.forms[0];
                                    form.target = "_blank";
                                    form.id = this.selectedPayment.slug;
                                    document.body.appendChild(form);
                                    form.submit();
                                    document.getElementById(this.selectedPayment.slug).remove();
                                } else {
                                    myStore.setAlert({ text: "ERR_BAD_REQUEST", classes: "error" });
                                }
                            } else if (response.success && response.data.payment.status && response.data.payment.status === "FAILED" && response.data.payment.errorMessage === "LIMIT_EXCEEDED") {
                                //Limit Exceeded
                                myStore.setAlert({ text: "LIMIT_EXCEEDED", classes: "error" });
                            } else if (response.success && response.data.payment.status && response.data.payment.status === "FAILED") {
                                //Failed
                                myStore.setAlert({ text: "GENERIC_ERROR", classes: "error" });
                            } else if (response.success && response.data.payment.status) {
                                //Withdrawal
                                myStore.setAlert({ text: "SUCCESS_WITHDRAW", classes: "success" });
                                router.push("/");
                            } else if (response.success && response.data.payment.errorMessage) {
                                myStore.setAlert({ text: response.data.payment.errorMessage, classes: "error" });
                            }
                            this.setCreatePaymentStatus("");
                        });
                    } else {
                        this.setCreatePaymentStatus("");
                    }
                }
            }
        },
    },
});
