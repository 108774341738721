const addGtmIFrame = (tagManagerId) => {
    // Add TagManager Iframe to the body
    let tagManagerIframe = document.createElement("iframe");
    tagManagerIframe.style.display = "none";
    tagManagerIframe.src = `https://www.googletagmanager.com/ns.html?id=${tagManagerId}`;
    document.body.appendChild(tagManagerIframe);
};

const createGtm = (tagManagerId) => {
    // Add TagManager Script
    window.dataLayer = window.dataLayer || [];

    (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
    })(window, document, "script", "dataLayer", tagManagerId);
};

const pushGtmEvent = (trigger, eventName, config, affiliateId, affiliateToken, liveAgentBtnId) => {
    let dataLayerObject;
    if (trigger === "GA4_TRIGGER") {
        dataLayerObject = {
            event: trigger,
            skin: config.skin,
            eventName,
            affiliateId,
            affiliateToken,
        };
    } else {
        dataLayerObject = {
            event: trigger,
            blueMediaCampaignId: config.blueMediaCampaignId,
            pixGamingScript: config.pixGamingScript,
            hotjarSkinId: config.hotjarSkinId,
            liveAgentId: config.liveAgentId,
            liveAgentBtnId,
            affiliateId,
        };
    }

    if (import.meta.env.VITE_ENV === "prod") {
        setTimeout(function () {
            window.dataLayer.push(dataLayerObject);
        }, 1000);
    }
};

export { addGtmIFrame, createGtm, pushGtmEvent };
