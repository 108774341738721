<template>
    <div class="email-verification-component mt-3">
        <template v-if="status === 'PENDING'">
            <dynamic componentPath="loader" folder="others" />
            <small>{{ getDictionary("VERIFYING_EMAIL") }}</small>
        </template>
        <dynamic class="pt-5" componentPath="error" folder="others" v-else-if="status === 'FAILED'" />
    </div>
</template>

<script>
import { verifyWithAuth, getUserCampaignBonuses, getUserBox } from "../../services/tma/axiosTmaRequests";
import { getPromotions, getBannerItems } from "../../services/strapi/axiosStrapiRequests";
import { setModalVisibility, createCookie, getCookie } from "../../helpers/helpers";

export default {
    name: "email-verification-component",
    data: function () {
        return {
            status: "PENDING",
        };
    },
    props: {
        code: String,
    },
    created() {
        this.myStore.setModalHeaderTitle("EMAIL_VERIFICATION");
        if (this.code === null) {
            this.myStore.setAlert({ text: "INVALID_CODE", classes: "error" });
            setModalVisibility(false, this.$router);
        } else {
            verifyWithAuth(this.configStore.config, this.code, this.callsStore, this.myStore, this.$router, this.perCallsStore).then((response) => {
                if (response.success) {
                    setModalVisibility(false, this.$router);
                    this.myStore.setAlert({ text: "SUCCESS_EMAIL_VERIFICATION", classes: "success" });
                    createCookie("depositedCustomer", "false", 365);

                    getUserCampaignBonuses(getCookie("userId"), this.configStore.config.skin, this.myStore, getCookie("accessToken"), this.perCallsStore, this.callsStore, this.$router).then((response) => {
                        if (response.success && response.campaignIdList) {
                            getPromotions(this.perCallsStore, this.callsStore, response.campaignIdList, this.configStore.config.skin, this.$router, this.myStore);
                            getBannerItems(this.perCallsStore, this.configStore.config.skin, this.myStore, this.callsStore, response.campaignIdList);
                        }
                    });
                    this.status = "SUCCESS";
                } else {
                    this.status = "FAILED";
                }
            });
        }
    },
};
</script>
