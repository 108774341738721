<template>
    <div class="my-container text-center" :class="margin">
        <router-link v-if="location" :to="location">
            <button class="skin-btn">{{ getDictionary(dictionary) }}</button>
        </router-link>
        <div class="btn-group" v-else-if="myStore.loggedIn && promotionsButton">
            <button type="button" class="btn skin-btn second no-border" :class="{'btn-shadow-left': btnShadow }" @click="redirect('/promotions')">{{ getDictionary("PROMOTIONS") }}</button>
            <button type="button" class="btn skin-btn" :class="{'btn-shadow-right': btnShadow }" @click="redirect('/payments/deposit')">{{ getDictionary("TOPUP") }}</button>
        </div>
        <router-link v-else-if="myStore.loggedIn" to="/payments/deposit"><button class="skin-btn" :class="{'btn-shadow-center': btnShadow }">{{ claim ? getDictionary("CLAIM") : getDictionary("TOPUP")  }}</button></router-link>
        <div class="btn-group" v-else-if="promotionsButton">
            <button type="button" class="btn skin-btn second" :class="{'btn-shadow-left': btnShadow }" @click="redirect('/promotions')">{{ getDictionary("PROMOTIONS") }}</button>
            <button type="button" class="btn skin-btn no-border" :class="{'btn-shadow-center': btnShadow }" @click="redirect('/register')">{{ getDictionary("REGISTER") }}</button>
            <button type="button" class="btn skin-btn second" :class="{'btn-shadow-right': btnShadow }" @click="redirect('/login')">{{ getDictionary("LOGIN") }}</button>
        </div>
        <router-link v-else-if="claim" to="/login"><button class="skin-btn" :class="{'btn-shadow-center': btnShadow }">{{ getDictionary("CLAIM") }}</button></router-link>
        <div class="btn-group" v-else>
            <button type="button" class="btn skin-btn no-border" :class="{'btn-shadow-left': btnShadow }" @click="redirect('/register')">{{ getDictionary("REGISTER") }}</button>
            <button type="button" class="btn skin-btn second" :class="{'btn-shadow-right': btnShadow }" @click="redirect('/login')">{{ getDictionary("LOGIN") }}</button>
        </div>
    </div>
</template>

<script>
export default {
    name: "button-component",
    props: {
        location: String,
        dictionary: String,
        btnShadow: Boolean,
        margin: String,
        promotionsButton: Boolean,
        claim: Boolean,
    },
    methods: {
        redirect(path) {
            this.$router.push(path);
        },
    },
};
</script>
