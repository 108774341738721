export default [
    "Live Blackjack Classic 17",
    "Live Blackjack Classic 18",
    "Live Blackjack Classic 11",
    "Live Blackjack Classic 19",
    "Live Blackjack Classic 12",
    "Live Blackjack Classic 20",
    "Live Blackjack Classic 15",
    "Live Blackjack Classic 13",
    "Live Blackjack Classic 24",
    "Live Blackjack Classic 25",
    "Live Blackjack Classic 26",
    "Live Blackjack Classic 22",
    "Live Blackjack Classic 27",
    "Live Speed Baccarat A",
    "Live Speed Baccarat I",
    "Live Blackjack 1 - Azure",
    "Live Blackjack 10 - Azure",
    "Live Blackjack 11 - Azure",
    "Live Blackjack 12 - Azure",
    "Live Blackjack 14 - Azure",
    "Live Blackjack 15 - Azure",
    "Live Blackjack 16 - Azure",
    "Live Blackjack 17 - Azure",
    "Live Blackjack 18 - Azure",
    "Live Blackjack 19 - Azure",
    "Live Blackjack 2 - Azure",
    "Live Blackjack 20 - Azure",
    "Live Blackjack 21 - Azure",
    "Live Blackjack 22 - Azure",
    "Live Blackjack 23 - Azure",
    "Live Blackjack 24 - Azure",
    "Live Blackjack 25 - Azure",
    "Live Blackjack 26 - Azure",
    "Live Blackjack 27 - Azure",
    "Live Blackjack 28 - Azure",
    "Live Blackjack 29 - Azure",
    "Live Blackjack 3 - Azure",
    "Live Blackjack 30 - Azure",
    "Live Blackjack 31 - Azure",
    "Live Blackjack 32 - Azure",
    "Live Blackjack 4 - Azure",
    "Live Blackjack 5 - Azure",
    "Live Blackjack 6 - Azure",
    "Live Blackjack 7 - Azure",
    "Live Blackjack 8 - Azure",
    "Live Blackjack 9 - Azure",
    "Live Blackjack 34 - Club",
    "Live Blackjack 33 - Club",
    "Live Blackjack 35 - Club",
    "Live Blackjack 36 - Club",
    "Live Roulette 10 - Ruby",
    "Live Speed Baccarat 1",
    "Live Speed Baccarat 2",
    "Live Speed Baccarat 3",
    "Live Speed Baccarat 5",
    "Live Speed Baccarat 6",
    "Live Speed Baccarat 7",
    "Live Speed Baccarat 9",
    "Live Speed Baccarat 8",
    "Live Speed Blackjack 3 - Ruby",
    "Live Speed Blackjack 4 - Ruby",
    "Live Classic Free Bet Blackjack 1",
    "Live Classic Free Bet Blackjack 7",
    "Live Classic Free Bet Blackjack 2",
    "Live Classic Free Bet Blackjack 3",
    "Live Classic Free Bet Blackjack 4",
    "Live Classic Free Bet Blackjack 5",
    "Live Classic Free Bet Blackjack 6",
    "Live Blackjack 68 - Ruby",
    "Live Blackjack 69 - Ruby",
    "Live Blackjack 70 - Ruby",
    "Live Grand Roulette: Hippodrome Casino Dual Play",
    "Live Roulette: Grand Casino Roulette Dual Play",
    "Live Roulette 9 - The Club",
    "Live Roulette 1 - Azure",
    "Live Roulette 2",
    "Live Baccarat 1",
    "Live Baccarat 2",
    "Live Baccarat 3",
    "Live Baccarat 4",
    "Live Baccarat 5",
    "Live Baccarat 6",
    "Live Baccarat 7",
    "Live Baccarat 8",
    "Live Baccarat 9",
    "Live Baccarat 10",
    "Live Royal Blackjack 1",
    "Live Royal Blackjack 2",
    "Live Royal blackjack 3",
    "Live ONE Blackjack",
    "Live Roulette 1",
    "Live Speed Roulette 1",
    "Live Speed Roulette 2",
    "Live Speed Roulette 3",
];
