import { getCookie } from "../../helpers/helpers";
import { setPromotions, setBanners, setPages } from "../../helpers/servicesHelpers";
import { promotionsQuery, dictionariesQuery, lobbiesQuery, pagesQuery, contentVersionQuery, paymentInfoQuery, loggedOutCampaignsQuery, bannerItemsQuery, vipQuery, shopQuery, websiteBlocksQuery, blackListedPlayersQuery, wheelRewardsQuery, userIpsQuery } from "./gqlStrapiQueries";
import { userIpMutation } from "./gqlStrapiMutations";
import { axiosStrapiInstance, axiosStrapiInstanceBo, errorHandling } from "../axiosInstances";

const getBannerItems = (perCallsStore, skin, myStore, callsStore, campaignIdList) => {
    if (perCallsStore.unFilteredBannerItemsList && perCallsStore.unFilteredBannerItemsList.length > 0) {
        setBanners(perCallsStore.unFilteredBannerItemsList, myStore, callsStore, perCallsStore, campaignIdList);
        return new Promise((resolve) => resolve({ success: true }));
    }

    let axiosInstance = skin === "bo" || skin === "lj" || skin === "zu" ? axiosStrapiInstanceBo : axiosStrapiInstance;
    return axiosInstance({
        method: "post",
        data: {
            query: bannerItemsQuery(skin, getCookie("cmsLanguageCode")),
        },
    })
        .then(function (response) {
            setBanners(response.data.data.bannerItems.data, myStore, callsStore, perCallsStore, campaignIdList);
            perCallsStore.setUnFilteredBannerItemsList(response.data.data.bannerItems.data);
            return { success: true };
        })
        .catch(function (error) {
            return errorHandling(error, myStore, "getBannerItems");
        });
};

const getPromotions = (perCallsStore, callsStore, campaignIdList, skin, router, myStore) => {
    if (perCallsStore.unFilteredPromotionsList && perCallsStore.unFilteredPromotionsList.length > 0) {
        setPromotions(router, perCallsStore.unFilteredPromotionsList, myStore, perCallsStore, callsStore, campaignIdList);
        return new Promise((resolve) => resolve({ success: true }));
    }

    let axiosInstance = skin === "bo" || skin === "lj" || skin === "zu" ? axiosStrapiInstanceBo : axiosStrapiInstance;
    return axiosInstance({
        method: "post",
        data: {
            query: promotionsQuery(skin, getCookie("cmsLanguageCode")),
        },
    })
        .then(function (response) {
            setPromotions(router, response.data.data.promotions.data, myStore, perCallsStore, callsStore, campaignIdList);
            perCallsStore.setUnFilteredPromotionsList(response.data.data.promotions.data);
            return { success: true };
        })
        .catch(function (error) {
            return errorHandling(error, myStore, "getPromotions");
        });
};

const getLoggedOutCampaigns = (skin, perCallsStore, myStore) => {
    if (perCallsStore.loggedOutCampaignsList && perCallsStore.loggedOutCampaignsList.length > 0) {
        return new Promise((resolve) => resolve(perCallsStore.loggedOutCampaignsList));
    }

    let axiosInstance = skin === "bo" || skin === "lj" || skin === "zu" ? axiosStrapiInstanceBo : axiosStrapiInstance;
    return axiosInstance({
        method: "post",
        data: {
            query: loggedOutCampaignsQuery(skin),
        },
    })
        .then(function (response) {
            let campaignIdList = [];
            response.data.data.loggedOutCampaigns.data.map((campaignId) => {
                campaignIdList.push(campaignId.attributes.campaignId);
            });

            perCallsStore.setLoggedOutCampaignsList(campaignIdList);
            return campaignIdList;
        })
        .catch(function (error) {
            return errorHandling(error, myStore, "getLoggedOutCampaigns");
        });
};

const getVipData = (skin, perCallsStore, myStore) => {
    if (perCallsStore.vipDataObject) {
        return new Promise((resolve) => resolve(perCallsStore.vipDataObject));
    }

    let axiosInstance = skin === "bo" || skin === "lj" || skin === "zu" ? axiosStrapiInstanceBo : axiosStrapiInstance;
    return axiosInstance({
        method: "post",
        data: {
            query: vipQuery(skin, getCookie("cmsLanguageCode")),
        },
    })
        .then(function (response) {
            perCallsStore.setVipDataObject(response.data.data.vips.data[0].attributes);
            return response.data.data.vips.data[0].attributes;
        })
        .catch(function (error) {
            return errorHandling(error, myStore, "getVipData");
        });
};

const getShopData = (skin, shopItemNameList, perCallsStore, myStore) => {
    if (perCallsStore.shopItemsDataArray && perCallsStore.shopItemsDataArray.length > 0) {
        return new Promise((resolve) => resolve({ success: true, data: perCallsStore.shopItemsDataArray }));
    }

    let axiosInstance = skin === "bo" || skin === "lj" || skin === "zu" ? axiosStrapiInstanceBo : axiosStrapiInstance;
    return axiosInstance({
        method: "post",
        data: {
            query: shopQuery(skin, getCookie("cmsLanguageCode"), shopItemNameList),
        },
    })
        .then(function (response) {
            perCallsStore.setShopItemsDataArray(response.data.data.shops.data);
            return { success: true, data: response.data.data.shops.data };
        })
        .catch(function (error) {
            return errorHandling(error, myStore, "getShopData");
        });
};

const getPages = (perCallsStore, skin, router, myStore) => {
    if (perCallsStore.pagesObject === null) {
        let axiosInstance = skin === "bo" || skin === "lj" || skin === "zu" ? axiosStrapiInstanceBo : axiosStrapiInstance;
        return axiosInstance({
            method: "post",
            data: {
                query: pagesQuery(skin, getCookie("cmsLanguageCode")),
            },
        })
            .then(function (response) {
                var pagesObject = {};
                response.data.data.pages.data.map((page) => {
                    pagesObject[page.attributes.slug] = page.attributes;
                });
                setPages(router, pagesObject, myStore);
                perCallsStore.setPagesObject(pagesObject);
            })
            .catch(function (error) {
                return errorHandling(error, myStore, "getPages");
            });
    } else {
        setPages(router, perCallsStore.pagesObject, myStore);
    }
};

const getWebsiteBlocks = (perCallsStore, skin, myStore) => {
    if (perCallsStore.websiteBlocksDataArray.length === 0) {
        let axiosInstance = skin === "bo" || skin === "lj" || skin === "zu" ? axiosStrapiInstanceBo : axiosStrapiInstance;
        return axiosInstance({
            method: "post",
            data: {
                query: websiteBlocksQuery(skin, getCookie("cmsLanguageCode")),
            },
        })
            .then(function (response) {
                perCallsStore.setWebsiteBlocksDataArray(response.data.data.websiteBlocks.data);
                return { success: true };
            })
            .catch(function (error) {
                return errorHandling(error, myStore, "getWebsiteBlocks");
            });
    } else {
        return new Promise((resolve) => resolve({ success: true }));
    }
};

const getDictionaries = (perCallsStore, myStore) => {
    if (perCallsStore.dictionaryObject === null) {
        return axiosStrapiInstance({
            method: "post",
            data: {
                query: dictionariesQuery(getCookie("cmsLanguageCode")),
            },
        })
            .then(function (response) {
                var dicObject = {};
                response.data.data.dictionaries.data.map((dictionary) => {
                    dicObject[dictionary.attributes.slug] = dictionary.attributes.text;
                });
                perCallsStore.setDictionaryObject(dicObject);
            })
            .catch(function (error) {
                return errorHandling(error, myStore, "getDictionaries");
            });
    }
};

const getLobbiesInfo = (skin, perCallsStore, myStore) => {
    if (perCallsStore.strapiLobbiesArray && perCallsStore.strapiLobbiesArray.length > 0) {
        return new Promise((resolve) => resolve({ strapiLobbiesArray: perCallsStore.strapiLobbiesArray, fromPersistedLocalStorage: true }));
    }

    let axiosInstance = skin === "bo" || skin === "lj" || skin === "zu" ? axiosStrapiInstanceBo : axiosStrapiInstance;
    return axiosInstance({
        method: "post",
        data: {
            query: lobbiesQuery(skin),
        },
    })
        .then(function (response) {
            perCallsStore.setStrapiLobbiesArray(response.data.data.lobbies.data);
            return { strapiLobbiesArray: response.data.data.lobbies.data, fromPersistedLocalStorage: false };
        })
        .catch(function (error) {
            return errorHandling(error, myStore, "getLobbiesInfo");
        });
};

const getContentVersion = (myStore, skin) => {
    let axiosInstance = skin === "bo" || skin === "lj" || skin === "zu" ? axiosStrapiInstanceBo : axiosStrapiInstance;
    return axiosInstance({
        method: "post",
        data: {
            query: contentVersionQuery(),
        },
    })
        .then(function (response) {
            return response.data.data.contentVersion.data.attributes.version;
        })
        .catch(function (error) {
            return errorHandling(error, myStore, "getContentVersion");
        });
};

const getPaymentInfo = (myStore, callsStore, skin) => {
    if (callsStore.paymentInfoList && callsStore.paymentInfoList.length !== 0) {
        return new Promise((resolve) => resolve(callsStore.paymentInfoList));
    }

    let axiosInstance = skin === "bo" || skin === "lj" || skin === "zu" ? axiosStrapiInstanceBo : axiosStrapiInstance;
    return axiosInstance({
        method: "post",
        data: {
            query: paymentInfoQuery(getCookie("cmsLanguageCode"), skin),
        },
    })
        .then(function (response) {
            callsStore.setPaymentInfoList(response.data.data.payments.data);
            return response.data.data.payments.data;
        })
        .catch(function (error) {
            return errorHandling(error, myStore, "getPaymentInfo");
        });
};

const getBlackListedPlayers = (perCallsStore, myStore) => {
    if (perCallsStore.blackListedPlayersArray && perCallsStore.blackListedPlayersArray.length > 0) {
        return new Promise((resolve) => resolve({ blackListedPlayersArray: perCallsStore.blackListedPlayersArray }));
    }

    return axiosStrapiInstance({
        method: "post",
        data: {
            query: blackListedPlayersQuery(),
        },
    })
        .then(function (response) {
            perCallsStore.setBlackListedPlayersArray(response.data.data.blackListedPlayers.data);
            return { blackListedPlayersArray: response.data.data.blackListedPlayers.data };
        })
        .catch(function (error) {
            return errorHandling(error, myStore, "getBlackListedPlayers");
        });
};

const getWheelRewards = (perCallsStore, myStore, skin) => {
    if (perCallsStore.wheelRewards && perCallsStore.wheelRewards.length > 0) {
        return new Promise((resolve) => resolve({ wheelRewards: perCallsStore.wheelRewards }));
    }

    let axiosInstance = skin === "bo" || skin === "lj" || skin === "zu" ? axiosStrapiInstanceBo : axiosStrapiInstance;
    return axiosInstance({
        method: "post",
        data: {
            query: wheelRewardsQuery(getCookie("cmsLanguageCode"), skin),
        },
    })
        .then(function (response) {
            let rewards = [];
            response.data.data.wheelOfFortunes.data.map((item) => {
                let reward = Object.assign(item.attributes, { id: item.attributes.bonusId });
                rewards = [...rewards, reward];
            });

            perCallsStore.setWheelRewards(rewards);
            return new Promise((resolve) => resolve({ wheelRewards: rewards }));
        })
        .catch(function (error) {
            return errorHandling(error, myStore, "getWheelRewards");
        });
};

const getUserIp = (myStore, skin, email, ip, fromDate) => {
    return axiosStrapiInstance({
        method: "post",
        data: {
            query: userIpsQuery(skin, email, ip, fromDate),
        },
    })
        .then(function (response) {
            console.log(response.data.data.userIps.data);

            return new Promise((resolve) => resolve({ userIps: response.data.data.userIps.data }));
        })
        .catch(function (error) {
            return errorHandling(error, myStore, "getUserIp");
        });
};

const insertUserIp = (email, ip, skin) => {
    return axiosStrapiInstance({
        method: "post",
        data: {
            query: userIpMutation(email, ip, skin),
        },
    })
        .then(function (response) {
            return new Promise((resolve) => resolve({ response }));
        })
        .catch(function (error) {
            return errorHandling(error, myStore, "insertUserIp");
        });
};

export { getBannerItems, getPromotions, getDictionaries, getLobbiesInfo, getPages, getContentVersion, getPaymentInfo, getLoggedOutCampaigns, getVipData, getShopData, getWebsiteBlocks, getBlackListedPlayers, getWheelRewards, getUserIp, insertUserIp };
