import { loadAnyScript } from "../helpers/helpers";

const getBlackbox = () => {
    try {
        if (window.IGLOO.getBlackbox() && window.IGLOO.getBlackbox().finished) {
            return window.IGLOO.getBlackbox().blackbox;
        } else {
            return null;
        }
    } catch (e) {
        return null;
    }
};
const initIovation = (iovationKey) => {
    if (import.meta.env.VITE_ENV === "prod") {
        // Iovation Configuration
        const version = "general5";
        window.io_global_object_name = "IGLOO";
        window.IGLOO = window.IGLOO || {
            loader: {
                version: version,
                subkey: iovationKey,
            },
        };

        // Call Scripts
        loadAnyScript("https://ci-mpsnare.iovation.com/snare.js", "iovationScript", (scriptStatus) => {
            // Reverse Proxy: whenever we find iojs in the url we redirect to https://first.iovation.com/
            loadAnyScript(`/iojs/${version}/${iovationKey}/static_wdp.js?loaderVer=5.1.0&compat=false&tp=true&tp_split=false&fp_static=true&fp_dyn=true&flash=false`, "iovation-static", (scriptStatus) => {});
            loadAnyScript(`/iojs/${version}/${iovationKey}/dyn_wdp.js?loaderVer=5.1.0&compat=false&tp=true&tp_split=false&fp_static=true&fp_dyn=true&flash=false`, "iovation-dynamic", (scriptStatus) => {});
        });
    }
};

export { initIovation, getBlackbox };
