<template>
    <div class="pending-withdrawals-component" v-if="callsStore.pendingWithdrawals.length > 0">
        <hr>
        <span class="menu-categories">{{ getDictionary("PENDING_WITHDRAWALS").toUpperCase() }}</span>
        <div class="my-block p-2 my-1" v-for="withdrawal in callsStore.pendingWithdrawals" :key="withdrawal.node.paymentId">
            <div class="col">{{ withdrawal.node.amount }}{{ callsStore.userCurrency }}</div>
            <button class="skin-btn small" :class="{'disabled' : status === 'PENDING'}" :disabled="status === 'PENDING'" @click="cancelPayment(withdrawal.node.paymentId)">{{ getDictionary("CANCEL") }}</button>
        </div>
        <hr>
    </div>
</template>

<script>
import { paymentConnection } from "../../../services/tma/axiosTmaRequests";
import { getCookie } from "../../../helpers/helpers";
import { cancelPayment, getBalances } from "../../../services/tma/axiosTmaRequests";

export default {
    name: "pending-withdrawals-component",
    data: function () {
        return {
            status: null,
        };
    },
    created() {
        this.getData(false);
    },
    methods: {
        getData(forceSearch) {
            let query = `userId: "${getCookie("userId")}", orderBy: {field:createdAt, direction:DESCENDING}`;
            paymentConnection(query, forceSearch, this.myStore, this.callsStore, getCookie("accessToken"), this.$router, this.configStore.config.skin, this.perCallsStore);
        },
        cancelPayment(paymentId) {
            this.status = "PENDING";
            cancelPayment(paymentId, this.myStore, getCookie("accessToken"), this.callsStore, this.$router, this.configStore.config.skin, this.perCallsStore).then((response) => {
                if (response.success) {
                    this.myStore.setAlert({ text: "SUCCESS_CANCEL_PAYMENT", classes: "success" });
                    // Update transaction history and balance
                    this.getData(true);
                    getBalances(getCookie("accessToken"), getCookie("userId"), this.callsStore, this.myStore, this.$router, this.configStore.config.skin, this.perCallsStore);
                    this.status = "SUCCESS";
                } else {
                    this.status = "FAILED";
                }
            });
        },
    },
};
</script>

<style scoped lang="scss">
.pending-withdrawals-component {
    .menu-categories {
        font-weight: bold;
        font-size: 12px;
        color: var(--first-color);
    }
}
</style>
