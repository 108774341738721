import { getUrl } from "../helpers/helpers";

const isDarkMode = () => window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches;
const seoFaviconRelated = (config) => {
    // Add Favicon on application load
    const faviconLink = document.createElement("link");
    faviconLink.rel = "shortcut icon";
    faviconLink.type = "image/png";
    faviconLink.href = isDarkMode() ? `${getUrl("ourAssets")}/ourAssets/${config.skin}/faviconDark.png` : `${getUrl("ourAssets")}/ourAssets/${config.skin}/favicon.png`;
    faviconLink.sizes = "32x32";
    document.head.appendChild(faviconLink);
};

const seoCanonicalRelated = (config) => {
    // Add canonical link on application load
    // Cononical link is used when there are multiple domains pointing to the same website. We need to let the search engine know which domain is the original, hence the canonical link.
    const canonical = document.createElement("link");
    canonical.id = "canonicalLink";
    canonical.rel = "canonical";
    canonical.href = config.originalDomain;
    document.head.appendChild(canonical);
};

const seoLanguageRelated = () => {
    // // Change html language on application load
    // document.documentElement.lang = "en";
    // // Add hreflang links on application load
    // const defaultHreflang = document.createElement("link");
    // defaultHreflang.hreflang = "x-default";
    // defaultHreflang.href = document.location.href;
    // defaultHreflang.rel = "alternate";
    // document.head.appendChild(defaultHreflang);
    // // Todo: languages should be changed to the website languages
    // const languages = ["en", "fr"];
    // languages.map((language) => {
    //     const hreflang = document.createElement("link");
    //     hreflang.hreflang = language;
    //     hreflang.href = `${document.location.href}${language}`;
    //     hreflang.rel = "alternate";
    //     document.head.appendChild(hreflang);
    // });
};

const seoRelated = (config) => {
    seoFaviconRelated(config);
    seoCanonicalRelated(config);
    // seoLanguageRelated();
};

export default seoRelated;
