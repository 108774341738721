<template>
    <dynamic componentPath="loader" folder="others" v-if="perCallsStore[`${lobbyType}LobbyIdsArray`].length === 0" />
    <template v-else>
        <template v-for="(lobby, index) in perCallsStore[`${lobbyType}LobbyIdsArray`]" :key="lobby.id">
            <lobby :lobby_id="lobby.id" :no_of_games="configStore.config.homeLobbyGames" />
        </template>
    </template>
</template>

<script>
import Lobby from "./lobby.vue";

export default {
    name: "lobbies-component",
    data: function () {
        return {};
    },
    components: {
        Lobby,
    },
    props: {
        lobbyType: String,
    },
};
</script>
